import React from 'react';
import Form404 from '../components/errors/Form404';
import { PAGE_NOT_FOUND } from '../config/text/text';

const Custom404 = () => {
  return (
    <>
      <Form404 />
    </>
  );
};

Custom404.title = PAGE_NOT_FOUND;
// Show the counterdown timer
Custom404.countdown = false;
Custom404.getMenu = true;

export default Custom404;
